import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
    if (window) {
        //window.console.log = function () { };
        //window.console.warn = function () { };
        //window.console.error = function() { };
    }
}
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
        navigator.serviceWorker.register('ngsw-worker.js');
    }
})
    .catch(err => console.log(err));
defineCustomElements(window);
